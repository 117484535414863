<script setup>
import { inject, reactive } from 'vue';
import useFetch from '@/main/resources/script/composables/network.js';
import displayNotification from '@/main/resources/script/composables/notification.js';

const config = inject('config');
const notification = reactive({
  type: '',
  message: '',
});
const modalOverlayState = reactive(config.modalOverlay);
const { t } = config.i18n.global;

function proceedToNextStep(data) {
  // Don't reset the form here
  modalOverlayState.type = config.modalOverlay.name.verifyPhoneOtp;
  modalOverlayState.data = data;
}

function sendPinOtpToNewPhone(event) {
  const countryCode = document.getElementById('country-code').value;
  const phone = document.getElementById('add-phone').value;
  useFetch({
    event,
    url: config.api.phoneAdd,
    method: 'post',
    body: {
      countryCode,
      phone,
    },
    successCallback: proceedToNextStep,
    errorCallback: (data) => {
      displayNotification(notification, t, data, () => {
        modalOverlayState.data = data;
      });
    },
  });
}
</script>

<template>
  <div class="no-logo-container short-header-container">
    {{ $t('label_add_phone') }}
  </div>
  <div
    v-show="notification.message"
    class="notification"
    :class="notification.type"
  >
    {{ notification.message }}
  </div>
  <form
    id="addPhoneForm"
    @submit.prevent="sendPinOtpToNewPhone($event)"
  >
    <div class="field">
      <label
        for="add-phone"
        class="field--hint input-field--country-label"
      >{{
        $t('placeholder_your_phone_number')
      }}</label>
      <input
        id="country-code"
        class="input-field--country-code"
        type="text"
        name="country-code"
        :value="modalOverlayState.data.countryCode ?? '+47'"
      >
      <input
        id="add-phone"
        type="text"
        class="input-field--country-code-phone"
        name="add-phone"
        :placeholder="$t('placeholder_your_phone_number')"
        :value="modalOverlayState.data.phone"
      >
      <div class="clear" />
    </div>
    <button
      type="submit"
      class="blue-button"
    >
      {{ $t('label_add_phone') }}
    </button>
  </form>
</template>
