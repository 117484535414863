<script setup>
import { inject, reactive } from 'vue';
import useFetch from '@/main/resources/script/composables/network.js';
import displayNotification from '@/main/resources/script/composables/notification.js';

const config = inject('config');
const notification = reactive({
  type: '',
  message: '',
});
const modalOverlayState = reactive(config.modalOverlay);
const { t } = config.i18n.global;

function proceedToNextStep(data) {
  // Don't reset the form here
  modalOverlayState.type = config.modalOverlay.name.verifyEmailOtp;
  modalOverlayState.data = data;
}

function startEmailVerification(event) {
  const email = document.getElementById('add-email').value;
  useFetch({
    event,
    url: config.api.emailAdd,
    method: 'post',
    body: { email },
    successCallback: proceedToNextStep,
    errorCallback: (data) => {
      displayNotification(notification, t, data, () => {
        modalOverlayState.data = data;
      });
    },
  });
}
</script>

<template>
  <div class="no-logo-container short-header-container">
    {{ $t('label_add_email') }}
  </div>
  <div
    v-show="notification.message"
    class="notification"
    :class="notification.type"
  >
    {{ notification.message }}
  </div>
  <form
    id="addEmailForm"
    @submit.prevent="startEmailVerification($event)"
  >
    <div class="field">
      <label for="field--hint">{{ $t('label_email') }}</label>
      <input
        id="add-email"
        type="text"
        name="add-email"
        :placeholder="$t('label_add_new_email')"
        :value="modalOverlayState.data.email"
      >
      <div
        class="field--hint field--bottom-hint"
        style="float: none;"
      >
        {{ $t('helper_text_add_email') }}
      </div>
    </div>
    <button
      type="submit"
      class="blue-button"
    >
      {{ $t('label_add_email') }}
    </button>
  </form>
</template>
