<script setup>
import {
  inject, reactive, watch,
} from 'vue';
import Information from '@/main/resources/script/components/notifications/information-component.vue';
import useFetch from '@/main/resources/script/composables/network.js';
import displayNotification from '@/main/resources/script/composables/notification.js';

defineProps({
  bankIdUserData: {
    type: Object,
    required: true,
  },
});

const config = inject('config');
const backendState = reactive(config.backendState);
const onCloseDataNotification = reactive(config.onCloseData.notification);
const { t } = config.i18n.global;
const notification = reactive({
  type: '',
  message: '',
});

watch(onCloseDataNotification, () => {
  if (Object.keys(onCloseDataNotification.passkey).length === 0) {
    return;
  }
  notification.type = onCloseDataNotification.passkey.notificationType.toLowerCase();
  notification.message = t(onCloseDataNotification.passkey.messageKey.toLowerCase());
});

function updateBankIdUserData(data) {
  const { bankIdUserData } = data;
  backendState.bankIdUserData = bankIdUserData;
}

function remove(event) {
  useFetch({
    event,
    url: config.api.removeBankId,
    method: 'delete',
    successCallback: (data) => {
      displayNotification(notification, t, data, () => {
        updateBankIdUserData(data);
      });
    },
    errorCallback: (data) => {
      displayNotification(notification, t, data);
    },
  });
}

/*
function add(event) {
  useFetch({
    event,
    url: config.api.addBankId,
    method: 'post',
    successCallback: (data) => {
      displayNotification(notification, t, data, () => {
        updateBankIdUserData(data);
      });
    },
    errorCallback: (data) => {
      displayNotification(notification, t, data);
    },
  });
}
*/
</script>

<template>
  <div class="profile-info">
    <div
      v-show="notification.message"
      class="notification"
      :class="notification.type"
      v-html="notification.message"
    />

    <Information v-if="!bankIdUserData.hasBankId">
      {{ $t('bankid_no_bankid') }}
    </Information>
    <!--
    <button
      v-if="!bankIdUserData.hasBankId"
      type="submit"
      class="blue-button"
      @click.prevent="add($event)"
    >
      Add BankID
    </button>
    -->
    <Information v-if="bankIdUserData.hasBankId">
      {{ $t('bankid_has_bankid') }}{{ bankIdUserData.created }}
    </Information>
    <div
      v-if="bankIdUserData.hasBankId"
      class="button button--warning button--small"
      style="margin-top: 24px"
      @click.prevent="remove($event)"
    >
      {{ $t('bankid_remove') }}
    </div>
  </div>
</template>
