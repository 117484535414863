<script setup>
import { inject, onMounted, reactive } from 'vue';
import useFetch from '@/main/resources/script/composables/network.js';
import displayNotification from '@/main/resources/script/composables/notification.js';

const config = inject('config');
const modalOverlayState = reactive(config.modalOverlay);
const backendState = reactive(config.backendState);
const onCloseDataNotification = reactive(config.onCloseData.notification);
const { t } = config.i18n.global;
const notification = reactive({
  type: modalOverlayState.data.notificationData.notificationType.toLowerCase(),
  message: t(
    modalOverlayState.data.notificationData.messageKey.toLowerCase(),
    { whereCodeWasSent: modalOverlayState.data.email },
  ),
});
let localResendLockTime;

function hideModalOverlay(data) {
  const {
    emails,
    notificationData,
  } = data;
  backendState.user.emails = emails;

  modalOverlayState.visible = false;
  modalOverlayState.type = '';
  modalOverlayState.data = {};

  onCloseDataNotification.email = notificationData;
}

function verifyOtp(event) {
  const { email } = modalOverlayState.data;
  const otp = document.getElementById('verification_code').value;
  useFetch({
    event,
    url: config.api.verifyEmailOtp,
    method: 'post',
    body: {
      email,
      otp,
    },
    successCallback: hideModalOverlay,
    errorCallback: (data) => {
      displayNotification(notification, t, data);
    },
  });
}

function unlockResend() {
  const resendButton = document.getElementById('resend-code-email');
  if (resendButton) {
    resendButton.classList.remove('disabled');
  }
}

function lockResend(resendLockTime) {
  const resendButton = document.getElementById('resend-code-email');
  if (resendButton) {
    resendButton.classList.add('disabled');
  }

  const currentTime = new Date();
  localResendLockTime = currentTime.setSeconds(
    currentTime.getSeconds() + modalOverlayState.data.resendLockTime,
  );

  setTimeout(() => {
    if (resendButton) {
      resendButton.classList.remove('disabled');
    }
  }, resendLockTime * 1000);
}

function resendCode(event) {
  const currentTime = new Date();
  if (currentTime.getTime() < localResendLockTime) {
    return;
  }
  const { email } = modalOverlayState.data;
  useFetch({
    event,
    url: config.api.resendEmailOtp,
    method: 'post',
    body: { email },
    successCallback: (data) => {
      displayNotification(notification, t, data, () => {
        const { resendLockTime } = data;
        lockResend(resendLockTime);
      });
    },
    errorCallback: (data) => {
      displayNotification(notification, t, data, unlockResend);
    },
  });
}

onMounted(() => {
  const currentTime = new Date();
  localResendLockTime = currentTime.setSeconds(
    currentTime.getSeconds() + modalOverlayState.data.resendLockTime,
  );

  setTimeout(() => {
    const resendButton = document.getElementById('resend-code-email');
    if (resendButton) {
      resendButton.classList.remove('disabled');
    }
  }, modalOverlayState.data.resendLockTime * 1000);
});
</script>

<template>
  <div class="no-logo-container short-header-container">
    {{ $t('label_add_email') }}
  </div>
  <div
    v-show="notification.message"
    class="notification"
    :class="notification.type"
    v-html="notification.message"
  />
  <form
    class="security-layout"
    @submit.prevent="verifyOtp($event)"
  >
    <div class="field">
      <label
        for="verification_code"
        class="field--hint field--hint__pin"
      >{{
        $t('label_verification_code')
      }}</label>
      <div
        class="pin-wrapper"
        style="width:206px"
      >
        <input
          id="verification_code"
          style="width:158px"
          class="pin"
          type="number"
          name="verification_code"
          inputmode="numeric"
          autocomplete="one-time-code"
        >
        <button
          type="submit"
          data-post-action="next"
          class="button blue-button blue-button--arrow"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <defs>
              <!-- eslint-disable max-len -->
              <path
                id="f"
                d="M13.468.24c-.273-.32-.726-.32-1.008 0a.89.89 0 000 1.136l5.11 5.815H.707c-.394 0-.706.356-.706.803 0 .448.312.815.706.815H17.57l-5.111 5.804c-.273.32-.273.837 0 1.146.282.321.736.321 1.008 0l6.32-7.191a.867.867 0 000-1.135L13.469.24z"
              />
              <!-- eslint-disable max-len -->
            </defs>
            <use
              fill="#ffffff"
              transform="translate(2 4)"
              xlink:href="#f"
            />
          </svg>
        </button>
      </div>
      <div class="resend-pin typography--body_small typography--center">
        {{ $t('did_not_get_the_code') }}
        <div
          id="resend-code-email"
          class="link text-link disabled timeout"
          @click.prevent="resendCode($event)"
        >
          {{ $t('label_resend') }}
        </div>
      </div>
      <div class="clear" />
    </div>
  </form>
</template>
