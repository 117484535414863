<script setup>
import { inject, reactive } from 'vue';
import useFetch from '@/main/resources/script/composables/network.js';
import displayNotification from '@/main/resources/script/composables/notification.js';

const config = inject('config');
const notification = reactive({
  type: '',
  message: '',
});
const modalOverlayState = reactive(config.modalOverlay);
const { t } = config.i18n.global;
const backendState = reactive(config.backendState);
const onCloseDataNotification = reactive(config.onCloseData.notification);

function hideModalOverlay(data) {
  const { notificationData } = data;
  backendState.passkeys = data.passkeys;

  modalOverlayState.visible = false;
  modalOverlayState.type = '';
  modalOverlayState.data = {};

  onCloseDataNotification.passkey = notificationData;
}

function decodeBase64UrlToUint8ArrayBuffer(base64String) {
  const formattedBase64 = base64String.replace(/-/g, '+')
    .replace(/_/g, '/');
  const byteCharacters = atob(formattedBase64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  return new Uint8Array(byteNumbers);
}

function encodeUin8ArrayBufferToBase64String(uin8ArrayBuffer) {
  const bytes = new Uint8Array(uin8ArrayBuffer);
  let str = '';
  bytes.forEach((charCode) => {
    str += String.fromCharCode(charCode);
  });
  return window.btoa(str)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

function signUpWithPasskey(data, event) {
  const {
    username,
    signupChallengeRequest,
  } = data;
  const credentialCreationOptions = JSON.parse(signupChallengeRequest);
  // Convert base64 binary data to actual binary data
  credentialCreationOptions.challenge = decodeBase64UrlToUint8ArrayBuffer(
    credentialCreationOptions.challenge,
  );
  credentialCreationOptions.user.id = decodeBase64UrlToUint8ArrayBuffer(
    credentialCreationOptions.user.id,
  );
  if (typeof credentialCreationOptions.excludeCredentials === 'object') {
    for (let i = 0; i < credentialCreationOptions.excludeCredentials.length; i += 1) {
      credentialCreationOptions.excludeCredentials[i] = {
        id: decodeBase64UrlToUint8ArrayBuffer(
          credentialCreationOptions.excludeCredentials[i].id,
        ),
        type: credentialCreationOptions.excludeCredentials[i].type,
      };
    }
  }

  const credentialsContainer = navigator.credentials; // CredentialsContainer
  const localCredentialOptions = {
    publicKey: credentialCreationOptions,
  };

  // Start the create process
  credentialsContainer.create(localCredentialOptions)
    .then((publicKeyCredential) => {
      // Transform publicKeyCredential to an object expected by backend
      const signupChallengeVerification = {};

      // Set regular values that doesn't require any transformation.
      // Note: we cannot simply copy `publicKeyCredential` object since it will cause issues with
      // overriding values from it.
      signupChallengeVerification.response = {};
      signupChallengeVerification.authenticatorAttachment = publicKeyCredential
        .authenticatorAttachment;
      signupChallengeVerification.id = publicKeyCredential.id;
      signupChallengeVerification.type = publicKeyCredential.type;
      if (typeof publicKeyCredential.response.getPublicKeyAlgorithm === 'function') {
        signupChallengeVerification.response.publicKeyAlgorithm = publicKeyCredential
          .response.getPublicKeyAlgorithm();
      }
      if (typeof publicKeyCredential.response.getTransports === 'function') {
        signupChallengeVerification.response.transports = publicKeyCredential
          .response.getTransports();
      }
      // Transform byte arrays to base64url
      signupChallengeVerification.rawId = encodeUin8ArrayBufferToBase64String(
        publicKeyCredential.rawId,
      );
      signupChallengeVerification.response.attestationObject = encodeUin8ArrayBufferToBase64String(
        publicKeyCredential.response.attestationObject,
      );
      signupChallengeVerification.response.clientDataJSON = encodeUin8ArrayBufferToBase64String(
        publicKeyCredential.response.clientDataJSON,
      );
      if (typeof publicKeyCredential.response.getAuthenticatorData === 'function') {
        // eslint-disable-next-line max-len
        signupChallengeVerification.response.authenticatorData = encodeUin8ArrayBufferToBase64String(
          publicKeyCredential.response.getAuthenticatorData(),
        );
      }
      if (typeof publicKeyCredential.response.getPublicKey === 'function') {
        signupChallengeVerification.response.publicKey = encodeUin8ArrayBufferToBase64String(
          publicKeyCredential.response.getPublicKey(),
        );
      }

      useFetch({
        event,
        url: config.api.addPasskey,
        method: 'post',
        body: {
          username,
          signupChallengeRequest,
          signupChallengeVerification: JSON.stringify(signupChallengeVerification),
        },
        successCallback: hideModalOverlay,
        errorCallback: (addPasskeyResponse) => {
          displayNotification(notification, t, addPasskeyResponse);
        },
      });
    })
    .catch((error) => {
      if (error.code === 11) {
        displayNotification(notification, t, {
          notificationData: {
            notificationType: 'error',
            messageKey: 'passkey_already_registered',
          },
        });
      }
    });
}

function getPasskeyChallenge(event) {
  const username = document.getElementById('username').value;
  useFetch({
    event,
    url: config.api.getPasskeyChallenge,
    method: 'post',
    body: { username },
    successCallback: signUpWithPasskey,
    errorCallback: (data) => {
      displayNotification(notification, t, data);
    },
  });
}
</script>

<template>
  <div class="no-logo-container short-header-container">
    {{ $t('label_add_passkey') }}
  </div>
  <div
    v-show="notification.message"
    class="notification"
    :class="notification.type"
  >
    {{ notification.message }}
  </div>

  <form
    id="add-passkey-form"
    @submit.prevent="getPasskeyChallenge($event)"
  >
    <div class="field">
      <label
        for="username"
        class="field--hint"
      >{{ $t('label_passkey_name') }}</label>
      <input
        id="username"
        type="text"
        name="username"
        :placeholder="$t('placeholder_passkey_name')"
      >
      <div class="clear" />
    </div>
    <button
      type="submit"
      class="blue-button"
    >
      {{ $t('label_add_passkey') }}
    </button>
  </form>
</template>
