<script setup>
import { inject, reactive } from 'vue';
import useFetch from '@/main/resources/script/composables/network.js';

const config = inject('config');
const modalOverlayState = reactive(config.modalOverlay);
const backendState = reactive(config.backendState);
const onCloseDataNotification = reactive(config.onCloseData.notification);
const notification = reactive({
  type: '',
  message: '',
});

function hideModalOverlayWithNewMessage(data) {
  const {
    authorizations,
    notificationData,
  } = data;
  modalOverlayState.visible = false;
  modalOverlayState.type = '';
  modalOverlayState.data = {};
  backendState.authorizations = authorizations;

  onCloseDataNotification.password = notificationData;
}

function hideModalOverlayWithOldMessage() {
  modalOverlayState.visible = false;
  modalOverlayState.type = '';

  onCloseDataNotification.password = modalOverlayState.data.notificationData;
  modalOverlayState.data = {};
}

function revokeAllTokens(event) {
  useFetch({
    event,
    url: config.api.tokenRevokeAll,
    method: 'post',
    successCallback: hideModalOverlayWithNewMessage,
    errorCallback: hideModalOverlayWithOldMessage,
  });
}
</script>

<template>
  <div
    v-show="notification.message"
    class="notification"
    :class="notification.type"
    v-html="notification.message"
  />
  <h3 class="modal-subtitle">
    {{ $t('do_you_want_to_revoke_tokens') }}
  </h3>
  <div class="modal-data">
    {{ $t('choose_yes_if_data_leaked') }}
  </div>
  <div
    class="blue-button blue-button--small"
    style="margin-top: 24px;"
    @click.prevent="revokeAllTokens($event)"
  >
    {{ $t('yes_log_out') }}
  </div>
  <div
    class="link blue-button blue-button--small blue-button--auxiliary margin-10"
    @click.prevent="hideModalOverlayWithOldMessage"
  >
    {{ $t('no_stay_logged_in') }}
  </div>
</template>
