export default function displayNotification(notification, t, data, update = () => {}) {
  if (typeof update === 'function') {
    // Call the possible data update
    update();
  }

  const { notificationData } = data;

  // eslint-disable-next-line no-param-reassign
  notification.type = notificationData.notificationType.toLowerCase();
  // eslint-disable-next-line no-param-reassign
  notification.message = t(notificationData.messageKey.toLowerCase());
}
