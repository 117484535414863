<script setup>
import { inject, reactive } from 'vue';
import Footer from '@/main/resources/script/components/static/footer-static-component.vue';
import HeaderPage from '@/main/resources/script/components/static/header-page-static-component.vue';
import Modal from '@/main/resources/script/components/modal-component.vue';
import Container from '@/main/resources/script/components/static/container-static-component.vue';
import Information from '@/main/resources/script/components/notifications/information-component.vue';
import useFetch from '@/main/resources/script/composables/network.js';
import displayNotification from '@/main/resources/script/composables/notification.js';

const config = inject('config');
const backendState = reactive(config.backendState);
const modalOverlayState = reactive(config.modalOverlay);
const { t } = config.i18n.global;
const notification = reactive({
  type: '',
  message: '',
});

function displayModalOverlay() {
  modalOverlayState.visible = true;
  modalOverlayState.type = config.modalOverlay.name.revokeTokensManual;
}

function revokeToken(clientId, event) {
  useFetch({
    event,
    url: config.api.tokenRevokeSingle,
    method: 'post',
    body: { clientId },
    successCallback: (data) => {
      const { authorizations } = data;
      backendState.authorizations = authorizations;
      notification.type = '';
      notification.message = '';
    },
    errorCallback: (data) => {
      displayNotification(notification, t, data);
    },
  });
}
</script>

<template>
  <HeaderPage
    :bread-crumb-link="config.navigation.root"
    :bread-crumb-text="$t('brand')"
    page-class="services"
    :page-name="$t('label_apps_services')"
  />
  <Modal
    v-if="modalOverlayState.visible"
    :type="modalOverlayState.type"
  />
  <Container>
    <Information>
      <template v-if="backendState.authorizations.length === 0">
        {{ $t('message_use_id') }}
        <br><br>
      </template>
      <template v-if="backendState.authorizations.length > 0">
        <span v-html="$t('message_using_id_for')" />
        <br><br>
      </template>
      <i18n-t keypath="message_no_services">
        <template #brand>
          {{ $t('brand') }}
        </template>
        <template #privacy>
          <router-link
            :to="config.navigation.privacy"
            class="link text-link"
          >
            {{
              $t('label_privacy')
                .toLowerCase()
            }}
          </router-link>
        </template>
      </i18n-t>
    </Information>
    <div
      v-show="notification.message"
      class="notification"
      :class="notification.type"
      v-html="notification.message"
    />
    <template
      v-for="(item, index) in backendState.authorizations"
      :key="index"
    >
      <div class="service-card">
        <div class="service-card__name">
          {{ item.clientRealName }}
        </div>
        <div class="service-card__authorized-date">
          {{ $t('authorised_on', { accessGrantedTime: item.creationTime }) }}
        </div>
        <ul class="service-card__list">
          <li v-if="item.scopeDescriptions.length === 0">
            {{ $t('authorised_sign_in') }}
          </li>
          <li
            v-for="(scopeDescription, scopeIndex) in item.scopeDescriptions"
            :key="scopeIndex"
          >
            {{ scopeDescription }}
          </li>
        </ul>
        <div
          class="link service-card__remove-access text-link"
          @click.prevent="revokeToken(item.clientId, $event)"
        >
          {{ $t('label_remove_access') }}
        </div>
      </div>
    </template>
    <div
      v-if="backendState.authorizations.length > 0"
      id="revoke-consent-modal-trigger"
      class="open-email-form"
      style="margin-top: 24px;"
      @click.prevent="displayModalOverlay"
    >
      {{ $t('label_remove_access_all') }}
    </div>
  </Container>
  <Footer />
</template>
