<script setup>
import { inject } from 'vue';
import '@/main/resources/assets/css/telenorid.css';
import Container from '@/main/resources/script/components/static/container-static-component.vue';
import Information from '@/main/resources/script/components/notifications/information-component.vue';
import HeaderPage from '@/main/resources/script/components/static/header-page-static-component.vue';
import Footer from '@/main/resources/script/components/static/footer-static-component.vue';

const config = inject('config');
</script>

<template>
  <HeaderPage
    :bread-crumb-link="config.navigation.root"
    :bread-crumb-text="$t('brand')"
    page-class="privacy"
    :page-name="$t('label_privacy')"
  />
  <Container class="reset-padding">
    <Information>
      {{ $t('privacy_description') }}
    </Information>
    <router-link
      :to="config.navigation.requestData"
      class="overview-card empty-container chevron-card"
    >
      <div class="privacy-request-card__header link text-link">
        {{ $t('privacy_get_data') }}
      </div>
      <div class="privacy-request-card__content">
        {{ $t('privacy_get_data_intro') }}
      </div>
    </router-link>
    <router-link
      :to="config.navigation.deleteAccount"
      class="overview-card empty-container chevron-card"
    >
      <div class="privacy-request-card__header link text-link">
        {{ $t('privacy_delete_account') }}
      </div>
      <div class="privacy-request-card__content">
        {{ $t('privacy_delete_account_intro') }}
      </div>
    </router-link>
  </Container>
  <Footer />
</template>
