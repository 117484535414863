import { createI18n } from 'vue-i18n';

// List of all locales.
// TODO(serhii): get from backend?
export const allLocales = ['en', 'no', 'sv'];

let i18n = null;

export function getI18n(defaultLocale, defaultTranslations) {
  i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: defaultLocale,
    fallbackLocale: 'no',
    messages: {
      [defaultLocale]: JSON.parse(defaultTranslations),
    },
  });
  return i18n;
}
