<script setup>
import { inject } from 'vue';
import Footer from '@/main/resources/script/components/static/footer-static-component.vue';
import Container from '@/main/resources/script/components/static/container-static-component.vue';
import HeaderPage from '@/main/resources/script/components/static/header-page-static-component.vue';

const config = inject('config');
</script>

<template>
  <HeaderPage
    :bread-crumb-link="config.navigation.root"
    :bread-crumb-text="$t('brand')"
    page-class="privacy"
    :page-name="$t('label_tnc')"
  />
  <Container
    class="tnc-content"
    v-html="$t('tnc_content')"
  />
  <Footer />
</template>
