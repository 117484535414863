<script setup>
import { inject, reactive, watch } from 'vue';
import useFetch from '@/main/resources/script/composables/network.js';
import displayNotification from '@/main/resources/script/composables/notification.js';
import verifyEditAccess from '@/main/resources/script/composables/comonRequests.js';

defineProps({
  items: {
    type: Array,
    required: true,
  },
  modalOverlayType: {
    type: String,
    required: true,
  },
  editingAllowed: {
    type: Boolean,
    required: true,
  },
});

const config = inject('config');
const modalOverlayState = reactive(config.modalOverlay);
const backendState = reactive(config.backendState);
const onCloseDataNotification = reactive(config.onCloseData.notification);
const { t } = config.i18n.global;
const notification = reactive({
  type: '',
  message: '',
});

watch(onCloseDataNotification, () => {
  if (Object.keys(onCloseDataNotification.phone).length === 0) {
    return;
  }
  notification.type = onCloseDataNotification.phone.notificationType.toLowerCase();
  notification.message = t(onCloseDataNotification.phone.messageKey.toLowerCase());
});

function remove(phoneId, phone, event) {
  useFetch({
    event,
    url: config.api.phoneRemove,
    method: 'post',
    body: {
      phoneId,
      phone,
    },
    successCallback: (data) => {
      displayNotification(notification, t, data, () => {
        const { phones } = data;
        backendState.user.phones = phones;
      });
    },
    errorCallback: (data) => {
      displayNotification(notification, t, data);
    },
  });
}
</script>

<template>
  <div class="profile-info">
    <div
      v-show="notification.message"
      class="notification"
      :class="notification.type"
      v-html="notification.message"
    />
    <span class="profile-info__title">{{ $t('label_phone_numbers') }}</span>
    <div
      v-if="editingAllowed"
      class="link profile-info__edit text-link"
      @click.prevent="verifyEditAccess(
        config.api.verifyAuthenticationEdit,
        modalOverlayState,
        modalOverlayType,
        $event)"
    >
      {{ $t('label_add_phone') }}
    </div>
    <div
      v-if="items.length === 0"
      class="empty-user-data"
    >
      {{ $t('placeholder_no_phones') }}
    </div>
    <template
      v-for="(item, index) in items"
      :key="index"
    >
      <div class="profile-info__data profile-info__data--table">
        <div class="profile-info__data--channel">
          {{ item.channel }}
        </div>
      </div>

      <div
        v-if="items.length > 1 && editingAllowed"
        class="email-controls"
      >
        <div
          class="link email-controls--remove"
          @click.prevent="remove(item.phoneId, item.channel, $event)"
        >
          {{ $t('label_remove') }}
        </div>
      </div>
    </template>
  </div>
</template>
