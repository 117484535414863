import { createApp, reactive } from 'vue';
import App from './App.vue';
import { getI18n } from './i18n.js';
import { useEventTrackerInitialise } from './composables/eventtracker.js';
import getRouter from './router.js';

// eslint-disable-next-line no-undef
const backendStateContainer = document.getElementById('backendState');
const backendState = JSON.parse(backendStateContainer.innerText);
backendStateContainer.remove();

const i18n = getI18n(backendState.languageTag, backendState.translations);
const config = reactive({
  api: {
    passwordChange: `${window.location.origin}/apiv2/password/change`,
    tokenRevokeAll: `${window.location.origin}/apiv2/token/revoke/all`,
    tokenRevokeSingle: `${window.location.origin}/apiv2/token/revoke/single`,
    emailAdd: `${window.location.origin}/apiv2/email/add`,
    emailSetPrimary: `${window.location.origin}/apiv2/email/set/primary`,
    emailRemove: `${window.location.origin}/apiv2/email/remove`,
    phoneAdd: `${window.location.origin}/apiv2/phone/add`,
    phoneRemove: `${window.location.origin}/apiv2/phone/remove`,
    verifyEmailOtp: `${window.location.origin}/apiv2/verify/email/otp`,
    resendEmailOtp: `${window.location.origin}/apiv2/verify/email/resend`,
    verifyPhoneOtp: `${window.location.origin}/apiv2/verify/phone/otp`,
    resendPhoneOtp: `${window.location.origin}/apiv2/verify/phone/resend`,
    getPasskeyChallenge: `${window.location.origin}/apiv2/passkey/challenge`,
    addPasskey: `${window.location.origin}/apiv2/passkey/add`,
    removePasskey: `${window.location.origin}/apiv2/passkey/remove`,
    removeAllPasskeys: `${window.location.origin}/apiv2/passkey/remove/all`,
    changeLanguage: `${window.location.origin}/apiv2/language/change`,
    verifyAuthentication: `${window.location.origin}/apiv2/authentication/verify`,
    verifyAuthenticationEdit: `${window.location.origin}/apiv2/authentication/verify/edit`,
    redirectToAuthentication: `${window.location.origin}/apiv2/authentication/redirect`,
    requestData: `${window.location.origin}/apiv2/privacy/data/request`,
    deleteData: `${window.location.origin}/apiv2/privacy/data/delete`,
    deleteAccount: `${window.location.origin}/apiv2/privacy/account/delete`,
    removeBankId: `${window.location.origin}/apiv2/bankid/delete`,
    addBankId: `${window.location.origin}/apiv2/bankid/add`,
  },
  // Modal state
  modalOverlay: {
    visible: false,
    type: '',
    name: {
      password: 'password',
      locale: 'locale',
      revokeTokens: 'revoke-tokens',
      revokeTokensManual: 'revoke-manual',
      email: 'email',
      phone: 'phone',
      verifyEmailOtp: 'verifyEmailOtp',
      verifyPhoneOtp: 'verifyPhoneOtp',
      passkey: 'passkey',
      deleteData: 'deleteData',
      deleteAccount: 'deleteAccount',
    },
    passThrough: '',
    data: {},
  },
  passkey: {
    available: false,
  },
  onCloseData: {
    notification: {
      email: {},
      password: {},
      phone: {},
      passkey: {},
    },
  },
  navigation: {
    root: '/',
    profile: '/profile',
    security: '/security',
    services: '/services',
    privacy: '/privacy',
    requestData: '/privacy/request-data',
    deleteData: '/privacy/delete-data',
    deleteAccount: '/privacy/delete-account',
    logout: '/logout',
    privacyNotice: '/privacy-notice',
    tnc: '/terms-of-service',
    error: '/error',
    bankId: '/bankid',
  },
  header: {
    profile: 'profile',
    security: 'security',
  },
  i18n,
  // This is backend
  // eslint-disable-next-line no-undef
  backendState,
});

const app = createApp(App);
app.provide('config', config);
app.use(i18n);
app.use(getRouter(config));
app.mount('#app');

// After app was mounted - set up the trackers
useEventTrackerInitialise();
