<script setup>
import { inject } from 'vue';
import Footer from '@/main/resources/script/components/static/footer-static-component.vue';
import Header from '@/main/resources/script/components/static/header-static-component.vue';

const config = inject('config');
</script>

<template>
  <Header />
  <router-link
    :to="config.navigation.profile"
    class="overview-card empty-container profile-card"
  >
    <div class="overview-card__header">
      {{ $t('label_profile_info') }}
    </div>
    <div class="overview-card__content">
      {{ $t('about_profile_info') }}
    </div>
  </router-link>
  <router-link
    :to="config.navigation.security"
    class="overview-card empty-container security-card"
  >
    <div class="overview-card__header">
      {{ $t('label_signin_options') }}
    </div>
    <div class="overview-card__content">
      {{ $t('about_signin_options') }}
    </div>
  </router-link>
  <router-link
    :to="config.navigation.services"
    class="overview-card empty-container services-card"
  >
    <div class="overview-card__header">
      {{ $t('label_apps_services') }}
    </div>
    <div class="overview-card__content">
      {{ $t('about_apps_services') }}
    </div>
  </router-link>
  <router-link
    :to="config.navigation.bankId"
    class="overview-card empty-container services-card"
  >
    <div class="overview-card__header">
      {{ $t('bankid_label') }}
    </div>
    <div class="overview-card__content">
      {{ $t('bankid_description') }}
    </div>
  </router-link>
  <router-link
    :to="config.navigation.privacy"
    class="overview-card empty-container privacy-card"
  >
    <div class="overview-card__header">
      {{ $t('label_privacy') }}
    </div>
    <div class="overview-card__content">
      {{ $t('about_privacy') }}
    </div>
  </router-link>
  <Footer />
</template>
