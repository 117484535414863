<script setup>
import { computed, inject } from 'vue';
import useFetch from '@/main/resources/script/composables/network.js';

const config = inject('config');
const { t } = config.i18n.global;

function redirectToAuthentication(event) {
  return useFetch({
    event,
    url: config.api.redirectToAuthentication,
    method: 'post',
    body: { href: config.navigation.root },
  });
}

const privacyLink = computed(() => {
  // The English privacy notice is placed within the same content as the English version of
  // terms and conditions therefore if the privacy notice url is equal to the terms and
  // condition we will just display the terms and conditions modal overlay, but automatically
  // scroll down on the page.

  const privacyAndTncUrlsAreSameResponse = {
    // this should happen in case of English locale
    href: config.navigation.privacyNotice,
    target: '_self',
  };

  const privacyAndTncUrlsAreDifferentResponse = {
    // this should happen in all other cases than when the locale is English
    href: t('norwegian_tnc_link'),
    target: '_blank',
  };

  return t('norwegian_tnc_link')
    ? privacyAndTncUrlsAreDifferentResponse
    : privacyAndTncUrlsAreSameResponse;
});

</script>
<template>
  <footer id="footer">
    <div
      id="footer-links"
      class="container container--footer"
    >
      <div class="footer-links">
        <a
          id="footerPolicyLink"
          class="getPrivacyLink text-link light-link"
          :href="privacyLink.href"
          :target="privacyLink.target"
        >
          {{ $t('label_privacy_notice') }}
        </a>
        <router-link
          id="footerGeneralLink"
          :to="config.navigation.tnc"
          class="getPrivacyLink text-link light-link"
        >
          {{ $t('label_terms_and_conditions') }}
        </router-link>
      </div>
    </div>
    <div class="container footer-action-wrapper">
      <a
        v-if="config.backendState.isAuthorized"
        href="/logout"
        class="link text-link sign-out-link footer-sign-link header-sign-link"
      >{{ $t('label_sign_out') }}</a>
      <div
        v-if="!config.backendState.isAuthorized"
        class="link text-link sign-in-link footer-sign-link header-sign-link"
        @click.prevent="redirectToAuthentication($event)"
      >
        {{ $t('label_sign_in') }}
      </div>
    </div>
  </footer>
</template>
