<script setup>
import { inject, reactive, ref } from 'vue';
import '@/main/resources/assets/css/telenorid.css';
import Container from '@/main/resources/script/components/static/container-static-component.vue';
import PasskeySection from '@/main/resources/script/components/passkey-section-component.vue';
import Modal from '@/main/resources/script/components/modal-component.vue';
import Information from '@/main/resources/script/components/notifications/information-component.vue';
import PhoneSection from '@/main/resources/script/components/phone-section-component.vue';
import EmailSection from '@/main/resources/script/components/email-section-component.vue';
import PasswordSection from '@/main/resources/script/components/password-section-component.vue';
import Footer from '@/main/resources/script/components/static/footer-static-component.vue';
import HeaderPage from '@/main/resources/script/components/static/header-page-static-component.vue';

const config = inject('config');
const backendState = ref(config.backendState);
const modalOverlayState = reactive(config.modalOverlay);
const passkeyState = reactive(config.passkey);

(function isApiAvailable() {
  if (!window.PublicKeyCredential) {
    return;
  }

  window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
    .then((available) => {
      if (available) {
        passkeyState.available = available;
      }
    })
    .catch(() => {
    });
}());
</script>

<template>
  <HeaderPage
    :bread-crumb-link="config.navigation.root"
    :bread-crumb-text="$t('brand')"
    page-class="sign-in-options"
    :page-name="$t('label_signin_options')"
  />
  <Modal
    v-if="modalOverlayState.visible"
    :type="modalOverlayState.type"
  />
  <Container>
    <div
      v-if="config.backendState.bankIdUserData.hasBankId"
      class="notification instruction"
    >
      {{ $t('bankid_edit_confirmation_info') }}
    </div>
    <Information>
      {{ $t('primary_channels_info') }}
    </Information>
    <PhoneSection
      :items="backendState.user.phones"
      :modal-overlay-type="config.modalOverlay.name.phone"
      :editing-allowed="true"
    />
    <EmailSection
      :items="backendState.user.emails.sort((a, b) => b.isPrimary - a.isPrimary)"
      :modal-overlay-type="config.modalOverlay.name.email"
      :editing-allowed="true"
    />
    <Information>
      {{ $t('forgot_password_info') }}
    </Information>
    <PasswordSection
      :modal-overlay-type="config.modalOverlay.name.password"
    />
    <Information>
      {{ $t('passkeys_about') }}
    </Information>
    <PasskeySection
      :api-available="passkeyState.available"
      :items="backendState.passkeys.credentials"
      :modal-overlay-type="config.modalOverlay.name.passkey"
    />
  </Container>
  <Footer />
</template>
