import useFetch from '@/main/resources/script/composables/network.js';

export default function verifyEditAccess(endpoint, modalOverlayState, modalOverlayType, t, event) {
  useFetch({
    event,
    url: endpoint,
    method: 'post',
    body: {},
    successCallback: () => {
      // eslint-disable-next-line no-param-reassign
      modalOverlayState.type = modalOverlayType;
      // eslint-disable-next-line no-param-reassign
      modalOverlayState.visible = true;
    },
  });
}
