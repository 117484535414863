<script setup>
import { inject, reactive, watch } from 'vue';
import useFetch from '@/main/resources/script/composables/network.js';
import displayNotification from '@/main/resources/script/composables/notification.js';
import verifyEditAccess from '@/main/resources/script/composables/comonRequests.js';

defineProps({
  items: {
    type: Array,
    required: true,
  },
  modalOverlayType: {
    type: String,
    required: true,
  },
  editingAllowed: {
    type: Boolean,
    required: true,
  },
});

const config = inject('config');
const modalOverlayState = reactive(config.modalOverlay);
const backendState = reactive(config.backendState);
const onCloseDataNotification = reactive(config.onCloseData.notification);
const { t } = config.i18n.global;
const notification = reactive({
  type: '',
  message: '',
});

watch(onCloseDataNotification, () => {
  if (Object.keys(onCloseDataNotification.email).length === 0) {
    return;
  }
  notification.type = onCloseDataNotification.email.notificationType.toLowerCase();
  notification.message = t(onCloseDataNotification.email.messageKey.toLowerCase());
});

function updateEmailData(data) {
  const { emails } = data;
  backendState.user.emails = emails;
}

function setAsPrimary(mailId, event) {
  useFetch({
    event,
    url: config.api.emailSetPrimary,
    method: 'post',
    body: { mailId },
    successCallback: (data) => {
      displayNotification(notification, t, data, () => {
        updateEmailData(data);
      });
    },
    errorCallback: (data) => {
      displayNotification(notification, t, data);
    },
  });
}

function remove(mailId, email, event) {
  useFetch({
    event,
    url: config.api.emailRemove,
    method: 'post',
    body: {
      mailId,
      email,
    },
    successCallback: (data) => {
      displayNotification(notification, t, data, () => {
        updateEmailData(data);
      });
    },
    errorCallback: (data) => {
      displayNotification(notification, t, data);
    },
  });
}
</script>

<template>
  <div class="profile-info">
    <div
      v-show="notification.message"
      class="notification"
      :class="notification.type"
      v-html="notification.message"
    />
    <span class="profile-info__title">{{ $t('label_email_addresses') }}</span>
    <div
      v-if="editingAllowed"
      class="link profile-info__edit text-link"
      @click.prevent="verifyEditAccess(
        config.api.verifyAuthenticationEdit,
        modalOverlayState,
        modalOverlayType,
        $event)"
    >
      {{ $t('label_add_email') }}
    </div>
    <div
      v-if="items.length === 0"
      class="empty-user-data"
    >
      {{ $t('placeholder_no_emails') }}
    </div>
    <template
      v-for="(item, index) in items"
      :key="index"
    >
      <div class="profile-info__data profile-info__data--table">
        <div class="profile-info__data--channel">
          {{ item.channel }}
        </div>
        <div
          v-if="item.isPrimary"
          class="profile-info__data--primary-label"
        >
          {{ $t('label_primary') }}
        </div>
      </div>
      <div
        v-if="!item.isPrimary && item.mailId && editingAllowed"
        class="email-controls"
      >
        <template v-if="item.isVerified">
          <div
            class="link text-link email-controls--set-primary"
            @click.prevent="setAsPrimary(item.mailId, $event)"
          >
            {{ $t('label_set_primary') }}
          </div>
          <div class="email-controls--separator">
            |
          </div>
        </template>
        <div
          class="link email-controls--remove"
          @click.prevent="remove(item.mailId, item.channel, $event)"
        >
          {{ $t('label_remove') }}
        </div>
      </div>
    </template>
  </div>
</template>
