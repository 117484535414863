const pageEventState = {
  pageWasRenderedTimestamp: null,
  currentInteractionStartTimestamp: null,
  currentInteractionEndTimestamp: null,
  clientTimeZoneOffset: null,
  clientTimeZone: null,
  pointerType: null,
  isTrusted: null,
  relativeX: null,
  relativeY: null,
  elementHeight: null,
  elementWidth: null,
};

function getCurrentTimestamp() {
  if (!Date) {
    return 0;
  }
  const date = new Date();
  return date.getTime();
}

function getCurrentTimezoneOffset() {
  if (!Date) {
    return 0;
  }
  const date = new Date();
  return date.getTimezoneOffset();
}

function getCurrentTimeZone() {
  if (typeof Intl !== 'undefined'
    && Intl.DateTimeFormat()
    && Intl.DateTimeFormat()
      .resolvedOptions()) {
    return Intl.DateTimeFormat()
      .resolvedOptions().timeZone;
  }
  return '';
}

export function useEventTrackerInitialise() {
  pageEventState.pageWasRenderedTimestamp = getCurrentTimestamp();
  pageEventState.currentInteractionStartTimestamp = getCurrentTimestamp();
}

export function useEventTrackerUpdateAfterPost() {
  pageEventState.currentInteractionStartTimestamp = getCurrentTimestamp();
}

export function useTrackStateBeforePost(event) {
  if (!event) {
    return;
  }
  pageEventState.currentInteractionEndTimestamp = getCurrentTimestamp();
  pageEventState.clientTimeZoneOffset = getCurrentTimezoneOffset();
  pageEventState.clientTimeZone = getCurrentTimeZone();
  pageEventState.pointerType = event.pointerType === '' ? 'key' : event.pointerType;
  pageEventState.isTrusted = event.isTrusted;

  if (event.currentTarget
    && typeof event.currentTarget.getBoundingClientRect === 'function'
    // Position of click makes no sense if submit key was pressed
    && (event.pointerType !== '')) {
    pageEventState.relativeX = event.clientX - event.currentTarget.getBoundingClientRect().x;
    pageEventState.relativeY = event.clientY - event.currentTarget.getBoundingClientRect().y;
    pageEventState.elementHeight = event.currentTarget.getBoundingClientRect().bottom
      - event.currentTarget.getBoundingClientRect().y;
    pageEventState.elementWidth = event.currentTarget.getBoundingClientRect().right
      - event.currentTarget.getBoundingClientRect().x;
  }
}

export function usePrepareEventFormData(formData) {
  Object
    .keys(pageEventState)
    .forEach((key) => formData.append(key, pageEventState[key]));
}
