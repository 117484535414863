<script setup>
import { inject, onBeforeMount } from 'vue';

const config = inject('config');
const { t } = config.i18n.global;

onBeforeMount(() => {
  document.title = t('brand');
});
</script>

<template>
  <router-view />
</template>
