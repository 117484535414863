<!--
  TODO(serhii): As of now this is an entry point,
   but it is practically serving the goal of being an Profile holder
-->
<script setup>
import { inject, reactive, ref } from 'vue';
// TODO(serhii): keep in mind that this is separate compiling phase
import '@/main/resources/assets/css/telenorid.css';
import Container from '@/main/resources/script/components/static/container-static-component.vue';
import Modal from '@/main/resources/script/components/modal-component.vue';
import Information from '@/main/resources/script/components/notifications/information-component.vue';
import PhoneSection from '@/main/resources/script/components/phone-section-component.vue';
import EmailSection from '@/main/resources/script/components/email-section-component.vue';
import LanguageSection from '@/main/resources/script/components/language-section-component.vue';
import HeaderPage from '@/main/resources/script/components/static/header-page-static-component.vue';
import Footer from '@/main/resources/script/components/static/footer-static-component.vue';

const config = inject('config');
const backendState = ref(config.backendState);
const modalOverlayState = reactive(config.modalOverlay);
const passkeyState = reactive(config.passkey);

(function isApiAvailable() {
  if (!window.PublicKeyCredential) {
    return;
  }

  window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable()
    .then((available) => {
      if (available) {
        passkeyState.available = available;
      }
    })
    .catch(() => {
    });
}());
</script>

<template>
  <HeaderPage
    :bread-crumb-link="config.navigation.root"
    :bread-crumb-text="$t('brand')"
    page-class="profile-info"
    :page-name="$t('label_profile_info')"
  />
  <Modal
    v-if="modalOverlayState.visible"
    :type="modalOverlayState.type"
  />
  <Container>
    <LanguageSection :fetch-url="config.api.changeLanguage" />
    <Information>
      <i18n-t keypath="message_edit_channel">
        <template #brand>
          {{ $t('brand') }}
        </template>
        <template #loginAndSecurity>
          <router-link
            :to="config.navigation.security"
            class="link text-link"
          >
            {{
              $t('label_signin_options')
                .toLowerCase()
            }}
          </router-link>
        </template>
      </i18n-t>
    </Information>
    <PhoneSection
      :items="backendState.user.phones"
      :modal-overlay-type="config.modalOverlay.name.phone"
      :editing-allowed="false"
    />
    <EmailSection
      :items="backendState.user.emails.sort((a, b) => b.isPrimary - a.isPrimary)"
      :modal-overlay-type="config.modalOverlay.name.email"
      :editing-allowed="false"
    />
  </Container>
  <Footer />
</template>
