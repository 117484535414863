<script setup>
import { inject } from 'vue';
import HeaderPage from '@/main/resources/script/components/static/header-page-static-component.vue';
import Footer from '@/main/resources/script/components/static/footer-static-component.vue';
import Container from '@/main/resources/script/components/static/container-static-component.vue';
import BankidSectionComponent from '@/main/resources/script/components/bankid-section-component.vue';

const config = inject('config');
</script>

<template>
  <HeaderPage
    :bread-crumb-link="config.navigation.root"
    :bread-crumb-text="$t('brand')"
    page-class=""
    :page-name="$t('bankid_label')"
  />
  <Container>
    <div class="center-container">
      <bankid-section-component :bank-id-user-data="config.backendState.bankIdUserData" />
    </div>
  </Container>
  <Footer />
</template>
