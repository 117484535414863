import { createRouter, createWebHistory } from 'vue-router';

import Overview from './pages/overview-page.vue';
import Profile from './pages/profile-page.vue';
import Security from './pages/security-page.vue';
import Services from './pages/services-page.vue';
import Privacy from './pages/privacy-page.vue';
import RequestData from './pages/request-data-page.vue';
import DeleteAccount from './pages/delete-account-page.vue';
import NotFoundPage from './pages/not-found-page.vue';
import PrivacyNotice from './pages/privacy-notice-page.vue';
import TermsOfService from './pages/terms-of-service-page.vue';
import BankId from './pages/bankid-page.vue';
import useFetch from './composables/network.js';

export default function getRouter(config) {
  const routes = [
    {
      path: config.navigation.root,
      name: 'root',
      component: Overview,
      meta: { requiresAuth: false },
    },
    {
      path: config.navigation.profile,
      component: Profile,
      meta: { requiresAuth: true },
    },
    {
      path: config.navigation.security,
      component: Security,
      meta: { requiresAuth: true },
    },
    {
      path: config.navigation.services,
      component: Services,
      meta: { requiresAuth: true },
    },
    {
      path: config.navigation.privacy,
      component: Privacy,
      meta: { requiresAuth: true },
    },
    {
      path: config.navigation.requestData,
      component: RequestData,
      meta: { requiresAuth: true },
    },
    {
      path: config.navigation.deleteAccount,
      component: DeleteAccount,
      meta: { requiresAuth: true },
    },
    {
      path: '/:pathMatch(.*)*',
      component: NotFoundPage,
      meta: { requiresAuth: false },
    },
    {
      path: config.navigation.privacyNotice,
      component: PrivacyNotice,
      meta: { requiresAuth: false },
    },
    {
      path: config.navigation.tnc,
      component: TermsOfService,
      meta: { requiresAuth: false },
    },
    {
      path: config.navigation.bankId,
      component: BankId,
      meta: { requiresAuth: true },
    },
  ];

  const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
  });

  async function verifyAuthentication(to, callback) {
    await useFetch({
      url: config.api.verifyAuthentication,
      method: 'post',
      body: { path: to.href },
      successCallback: callback,
      errorCallback: callback,
    });
  }

  router.beforeEach(async (to) => {
    // Allow root
    if (!to.meta.requiresAuth) {
      return true;
    }

    let accessAllowed = false;

    function setAccess(data) {
      if (!data) {
        accessAllowed = false;
        return;
      }
      const { canAccess } = data;
      accessAllowed = typeof canAccess === 'boolean' ? canAccess : false;
    }

    await verifyAuthentication(to, setAccess);
    return accessAllowed;
  });

  return router;
}
