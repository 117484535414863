<template>
  <header>
    <div class="container">
      <img
        src="@/main/resources/images/logo-new.svg"
        class="telenorid"
      >
    </div>
  </header>
</template>
