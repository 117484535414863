<script setup>
import { inject } from 'vue';
import HeaderPage from '@/main/resources/script/components/static/header-page-static-component.vue';
import Footer from '@/main/resources/script/components/static/footer-static-component.vue';
import Container from '@/main/resources/script/components/static/container-static-component.vue';

const config = inject('config');
</script>

<template>
  <HeaderPage
    :bread-crumb-link="config.navigation.root"
    :bread-crumb-text="$t('brand')"
    page-class=""
    :page-name="$t('heading_page_not_found')"
  />
  <Container>
    <div class="container security-layout">
      {{ $t('message_page_not_found_text') }}
    </div>
    <div class="center-container">
      <router-link
        :to="config.navigation.root"
        class="center-button"
      >
        {{ $t('link_page_not_found_text') }}
      </router-link>
    </div>
  </Container>
  <Footer />
</template>
