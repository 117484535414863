<script setup>
import { inject, reactive } from 'vue';
import useFetch from '@/main/resources/script/composables/network.js';
import displayNotification from '@/main/resources/script/composables/notification.js';

const config = inject('config');
const notification = reactive({
  type: '',
  message: '',
});
const { t } = config.i18n.global;
const confirmationPhrase = t('privacy_request_account_deletion_button');

function deleteAccount() {
  const userConfirmation = document.getElementById('delete-account-confirm').value;
  if (userConfirmation !== confirmationPhrase) {
    return;
  }
  useFetch({
    url: config.api.deleteAccount,
    method: 'post',
    errorCallback: (data) => {
      displayNotification(notification, t, data);
    },
  });
}

function toggleButtonState() {
  const userConfirmation = document.getElementById('delete-account-confirm').value;
  const button = document.getElementById('delete-account-confirm-button');
  if (userConfirmation !== confirmationPhrase) {
    button.setAttribute('disabled', '');
  } else {
    button.removeAttribute('disabled');
  }
}
</script>

<template>
  <div class="no-logo-container short-header-container">
    {{ $t('privacy_modal_overlay_title') }}
  </div>
  <div
    v-show="notification.message"
    class="notification"
    :class="notification.type"
  >
    {{ notification.message }}
  </div>
  <div>
    {{ $t('privacy_request_account_deletion_expectation_intro') }}
    {{ $t('privacy_request_account_deletion_expectation_warning') }}
    <div class="modal-data modal-subtitle--warning">
      {{ $t('privacy_request_account_deletion_expectation_restoration') }}
    </div>
    <br>
  </div>
  <div class="field">
    <label
      for="field--hint"
      v-html="$t('privacy_modal_overlay_challenge', { confirmationPhrase })"
    />
    <input
      id="delete-account-confirm"
      type="text"
      name="delete-data"
      @keyup="toggleButtonState"
    >
  </div>
  <button
    id="delete-account-confirm-button"
    type="submit"
    class="blue-button"
    disabled
    @click.prevent="deleteAccount"
  >
    {{ $t('privacy_request_account_deletion_modal_overlay_button') }}
  </button>
</template>
