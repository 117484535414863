<script setup>
import { inject, reactive, watch } from 'vue';
import verifyEditAccess from '@/main/resources/script/composables/comonRequests.js';

defineProps({
  modalOverlayType: {
    type: String,
    required: true,
  },
});

const config = inject('config');
const modalOverlayState = reactive(config.modalOverlay);
const onCloseDataNotification = reactive(config.onCloseData.notification);
const { t } = config.i18n.global;
const notification = reactive({
  type: '',
  message: '',
});

watch(onCloseDataNotification, () => {
  if (Object.keys(onCloseDataNotification.password).length === 0) {
    return;
  }
  notification.type = onCloseDataNotification.password.notificationType.toLowerCase();
  notification.message = t(onCloseDataNotification.password.messageKey.toLowerCase());
});
</script>

<template>
  <div class="profile-info">
    <div
      v-show="notification.message"
      class="notification"
      :class="notification.type"
      v-html="notification.message"
    />
    <span class="profile-info__title">{{ $t('label_password') }}</span>
    <div
      class="link profile-info__edit text-link"
      @click.prevent="verifyEditAccess(
        config.api.verifyAuthenticationEdit,
        modalOverlayState,
        modalOverlayType,
        $event)"
    >
      {{ $t('label_change_password') }}
    </div>
    <div class="profile-info__data profile-info__data--table">
      <div class="profile-info__data--channel">
        {{ $t('placeholder_password') }}
      </div>
    </div>
  </div>
</template>
