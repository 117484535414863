<script setup>
import { inject, reactive } from 'vue';
import useFetch from '@/main/resources/script/composables/network.js';

const config = inject('config');
const backendState = reactive(config.backendState);
const modalOverlayState = reactive(config.modalOverlay);
const notification = reactive({
  type: '',
  message: '',
});

function hideModalOverlayAndUpdate(data) {
  const { authorizations } = data;
  backendState.authorizations = authorizations;

  modalOverlayState.visible = false;
  modalOverlayState.type = '';
  modalOverlayState.data = {};
}

function hideModalOverlay() {
  modalOverlayState.visible = false;
  modalOverlayState.type = '';
  modalOverlayState.data = {};
}

function revokeAllTokens(event) {
  useFetch({
    event,
    url: config.api.tokenRevokeAll,
    method: 'post',
    successCallback: hideModalOverlayAndUpdate,
    errorCallback: hideModalOverlay,
  });
}
</script>

<template>
  <div
    v-show="notification.message"
    class="notification"
    :class="notification.type"
    v-html="notification.message"
  />
  <h1 class="modal-title">
    {{ $t('revoke_access_overlay_title') }}
  </h1>
  <h3
    class="modal-subtitle"
    v-html="$t('revoke_access_overlay_subtitle')"
  />
  <div class="modal-data">
    {{ $t('revoke_access_overlay_data') }}
  </div>
  <div
    class="blue-button blue-button--small"
    style="margin-top: 24px;"
    @click.prevent="revokeAllTokens($event)"
  >
    {{ $t('label_revoke') }}
  </div>
  <div
    class="link blue-button blue-button--small blue-button--auxiliary margin-10"
    @click.prevent="hideModalOverlay"
  >
    {{ $t('label_cancel') }}
  </div>
</template>
