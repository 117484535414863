<script setup>
import { inject, reactive } from 'vue';
import { allLocales } from '@/main/resources/script/i18n.js';
import useFetch from '@/main/resources/script/composables/network.js';
import displayNotification from '@/main/resources/script/composables/notification.js';

defineProps({
  fetchUrl: {
    type: String,
    required: true,
  },
});

const config = inject('config');
const notification = reactive({
  type: '',
  message: '',
});
const { t } = config.i18n.global;

function applyLocale(data) {
  const {
    locale,
    translations,
    notificationData,
    privacyNoticeUrl,
    termsAndConditionUrl,
  } = data;

  // Add locale.
  config.i18n.global.setLocaleMessage(locale, JSON.parse(translations));

  // Set locale.
  config.i18n.global.locale = locale;

  // Locale on user account had changed. Update
  config.backendState.userAccountLanguageTag = locale;

  if (privacyNoticeUrl) {
    config.backendState.privacyNoticeUrl = privacyNoticeUrl;
  }

  if (termsAndConditionUrl) {
    config.backendState.termsAndConditionsUrl = termsAndConditionUrl;
  }

  notification.type = notificationData.notificationType.toLowerCase();
  notification.message = t(notificationData.messageKey.toLowerCase());
}

// Fetch locale.
function loadLocale(locale, fetchUrl) {
  useFetch({
    url: fetchUrl,
    method: 'post',
    body: { locale },
    successCallback: applyLocale,
    errorCallback: (data) => {
      displayNotification(notification, t, data);
    },
  });
}
</script>

<template>
  <div class="profile-info">
    <div
      v-show="notification.message"
      class="notification"
      :class="notification.type"
      v-html="notification.message"
    />
    <label
      class="profile-info__title"
      for="locale"
    >{{ $t('label_language') }}</label>
    <div class="profile-info__data profile-info__data--table">
      <i class="arrow down" />
      <select
        id="locale"
        :value="config.backendState.userAccountLanguageTag"
        @change="loadLocale($event.target.value, fetchUrl)"
      >
        <option
          v-for="(locale, index) in allLocales"
          :key="index"
          :value="locale"
        >
          {{ $t(`language_${locale}`) }}
        </option>
      </select>
    </div>
  </div>
</template>

<style>
.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    z-index: 3;
    position: absolute;
    top: 20px;
    right: 20px;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}
</style>
