import {
  useEventTrackerUpdateAfterPost,
  usePrepareEventFormData,
  useTrackStateBeforePost,
} from './eventtracker.js';

export default async function useFetch({
  url,
  method,
  body,
  successCallback = () => {
  },
  errorCallback = () => {
  },
  event,
}) {
  const init = {
    method,
    headers: {
      'X-MANAGE-TELENORID-COM-CSRF-PROTECTION': 1,
    },
    credentials: 'same-origin',
    redirect: 'follow',
  };

  if (body) {
    const formData = new FormData();
    Object
      .keys(body)
      .forEach((key) => formData.append(key, body[key]));

    if (event) {
      useTrackStateBeforePost(event);
      usePrepareEventFormData(formData);
    }

    if (!body.path) {
      // Add path we're currently on so backend know where to return in case of API authentication
      formData.append('path', window.location.pathname);
    }

    init.body = formData;
  }

  function successOutcome(data, initialEvent) {
    useEventTrackerUpdateAfterPost();
    successCallback(data, initialEvent);
  }

  function errorOutcome(data, initialEvent) {
    useEventTrackerUpdateAfterPost();
    errorCallback(data, initialEvent);
  }

  try {
    // Always add query parameters to request
    const response = await fetch(url + window.location.search, init);
    // Forbidden
    let content;
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      content = await response.json();
    } else {
      content = await response.text();
    }

    if (response.status === 403) {
      errorOutcome(content, event);
      return;
    }

    // Redirect has to be done this way due to cors policy
    if (response.status === 302 || response.status === 401) {
      window.location.href = content.uri;
      return;
    }
    successOutcome(content, event);
  } catch (error) {
    errorOutcome(error, event);
  }
}
