<script setup>
import { inject, reactive } from 'vue';
import '@/main/resources/assets/css/telenorid.css';
import Container from '@/main/resources/script/components/static/container-static-component.vue';
import Information from '@/main/resources/script/components/notifications/information-component.vue';
import HeaderPage from '@/main/resources/script/components/static/header-page-static-component.vue';
import Footer from '@/main/resources/script/components/static/footer-static-component.vue';
import useFetch from '@/main/resources/script/composables/network.js';

const config = inject('config');
const { t } = config.i18n.global;
const notification = reactive({
  type: '',
  message: '',
});

function download(data) {
  const {
    content,
    notificationData,
  } = data;
  const a = document.createElement('a');
  const date = new Date();
  a.download = `Telenor-ID-data-export-${date.toISOString()}.html`;
  a.href = window.URL.createObjectURL(new Blob([content], { type: 'text/html' }));
  a.click();
  URL.revokeObjectURL(a.href);

  notification.type = notificationData.notificationType.toLowerCase();
  notification.message = t(notificationData.messageKey.toLowerCase());

  document.getElementById('request-data-button')
    .setAttribute('disabled', '');
}

function toggle() {
  document.getElementById('what-can-I-expect-collapsible-button')
    .classList
    .toggle('collapsible__button--expanded');
  document.getElementById('what-can-I-expect-collapsible-content')
    .classList
    .toggle('collapsible__content--expanded');
}

function displayNotification(data) {
  const { notificationData } = data;

  notification.type = notificationData.notificationType.toLowerCase();
  notification.message = t(notificationData.messageKey.toLowerCase());
}

function requestReport(event) {
  useFetch({
    event,
    url: config.api.requestData,
    method: 'post',
    successCallback: download,
    errorCallback: displayNotification,
  });
}
</script>

<template>
  <HeaderPage
    :bread-crumb-link="config.navigation.privacy"
    :bread-crumb-text="$t('label_privacy')"
    page-class="privacy"
    :page-name="$t('privacy_get_data')"
  />
  <Container>
    <div
      v-show="notification.message"
      class="notification"
      :class="notification.type"
      v-html="notification.message"
    />
    <Information>
      {{ $t('privacy_request_data_intro') }}
    </Information>
    <div class="privacy-request">
      <p
        class="privacy-request__paragraph"
        v-html="$t('privacy_request_data_disclaimer')"
      />
      <p class="privacy-request__paragraph">
        {{ $t('privacy_request_data_store_secure') }}
      </p>

      <div
        id="what-can-I-expect-collapsible"
        class="collapsible privacy-request-details"
      >
        <div
          id="what-can-I-expect-collapsible-button"
          class="collapsible__button"
          @click.prevent="toggle"
        >
          {{ $t('privacy_request_data_expectation_title') }}
        </div>
        <div
          id="what-can-I-expect-collapsible-content"
          class="collapsible__content"
        >
          <p class="privacy-request__paragraph">
            {{ $t('privacy_request_data_expectation_intro') }}
          </p>
          <ul>
            <li class="privacy-request-details__bullet-point">
              {{ $t('privacy_request_data_expectation_details_profile_information') }}
            </li>
            <li class="privacy-request-details__bullet-point">
              {{ $t('privacy_request_data_expectation_details_usage_history') }}
            </li>
            <li class="privacy-request-details__bullet-point">
              {{ $t('privacy_request_data_expectation_details_connected_services') }}
            </li>
          </ul>
        </div>
      </div>
      <button
        id="request-data-button"
        class="security-layout button button--secondary button--small"
        @click.prevent="requestReport($event)"
      >
        {{ $t('privacy_request_data_button') }}
      </button>
    </div>
  </Container>
  <Footer />
</template>
