<script setup>
import { inject, reactive } from 'vue';
import useFetch from '../../composables/network.js';
import Information from '@/main/resources/script/components/notifications/information-component.vue';
import displayNotification from '@/main/resources/script/composables/notification.js';

const config = inject('config');
const notification = reactive({
  type: '',
  message: '',
});
const modalOverlayState = reactive(config.modalOverlay);
const { t } = config.i18n.global;

function proceedToNextStep(data) {
  document.getElementById('changePasswordForm')
    .reset();

  modalOverlayState.type = config.modalOverlay.name.revokeTokens;
  modalOverlayState.data = data;
}

function changePassword(event) {
  const newPassword = document.getElementById('new-password').value;
  const repeatNewPassword = document.getElementById('repeat-new-password').value;
  const currentPassword = document.getElementById('current-password').value;
  useFetch({
    event,
    url: config.api.passwordChange,
    method: 'post',
    body: {
      current_password: currentPassword,
      new_password: newPassword,
      repeat_new_password: repeatNewPassword,
    },
    successCallback: proceedToNextStep,
    errorCallback: (data) => {
      displayNotification(notification, t, data);
    },
  });
}
</script>

<template>
  <div class="no-logo-container short-header-container">
    {{ $t('label_change_password') }}
  </div>
  <Information>
    {{ $t('do_not_reuse_passwords') }}
  </Information>
  <div
    v-show="notification.message"
    class="notification"
    :class="notification.type"
    v-html="notification.message"
  />
  <form
    id="changePasswordForm"
    @submit.prevent="changePassword($event)"
  >
    <div class="fieldWrap">
      <div class="formWrapTopLabelHolder">
        <label for="current-password">{{ $t('label_current_password') }}</label>
      </div>
      <input
        id="current-password"
        type="password"
        name="current-password"
        :placeholder="$t('label_current_password')"
      >
    </div>
    <div class="fieldWrap">
      <div class="formWrapTopLabelHolder">
        <label for="new-password">{{ $t('label_new_password') }}</label>
      </div>
      <input
        id="new-password"
        type="password"
        name="new-password"
        :placeholder="$t('label_new_password')"
      >
    </div>
    <div class="fieldWrap">
      <div class="formWrapTopLabelHolder">
        <label for="repeat-new-password">{{ $t('label_repeat_new_password') }}</label>
      </div>
      <input
        id="repeat-new-password"
        type="password"
        name="repeat-new-password"
        :placeholder="$t('label_repeat_new_password')"
      >
    </div>
    <button
      type="submit"
      class="blue-button"
    >
      {{ $t('label_change_password') }}
    </button>
  </form>
</template>
