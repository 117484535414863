<script setup>
import { inject, reactive, watch } from 'vue';
import useFetch from '@/main/resources/script/composables/network.js';
import displayNotification from '@/main/resources/script/composables/notification.js';
import verifyEditAccess from '@/main/resources/script/composables/comonRequests.js';

defineProps({
  items: {
    type: Array,
    required: true,
  },
  apiAvailable: {
    type: Boolean,
    required: true,
    default: false,
  },
  modalOverlayType: {
    type: String,
    required: true,
  },
});

const config = inject('config');
const modalOverlayState = reactive(config.modalOverlay);
const backendState = reactive(config.backendState);
const onCloseDataNotification = reactive(config.onCloseData.notification);
const { t } = config.i18n.global;
const notification = reactive({
  type: '',
  message: '',
});

watch(onCloseDataNotification, () => {
  if (Object.keys(onCloseDataNotification.passkey).length === 0) {
    return;
  }
  notification.type = onCloseDataNotification.passkey.notificationType.toLowerCase();
  notification.message = t(onCloseDataNotification.passkey.messageKey.toLowerCase());
});

function updatePasskeyData(data) {
  const { passkeys } = data;
  backendState.passkeys = passkeys;
}

function remove(credentialId, event) {
  useFetch({
    event,
    url: config.api.removePasskey,
    method: 'post',
    body: { credentialId },
    successCallback: (data) => {
      displayNotification(notification, t, data, () => {
        updatePasskeyData(data);
      });
    },
    errorCallback: (data) => {
      displayNotification(notification, t, data);
    },
  });
}

function removeAll(event) {
  useFetch({
    event,
    url: config.api.removeAllPasskeys,
    method: 'post',
    successCallback: (data) => {
      displayNotification(notification, t, data, () => {
        updatePasskeyData(data);
      });
    },
    errorCallback: (data) => {
      displayNotification(notification, t, data);
    },
  });
}
</script>

<template>
  <div class="profile-info">
    <div
      v-show="notification.message"
      class="notification"
      :class="notification.type"
      v-html="notification.message"
    />
    <span class="profile-info__title">{{ $t('label_passkeys') }}</span>
    <div
      v-if="apiAvailable"
      class="link profile-info__edit text-link"
      @click.prevent="verifyEditAccess(
        config.api.verifyAuthenticationEdit,
        modalOverlayState,
        modalOverlayType,
        $event)"
    >
      {{ $t('label_add_passkey') }}
    </div>
    <div
      v-if="items.length === 0"
      class="empty-user-data"
    >
      {{ $t('placeholder_no_passkeys') }}
    </div>
    <div
      v-for="(item, index) in items"
      :key="index"
      class="service-card"
    >
      <div class="service-card__name">
        {{ item.username }}
      </div>
      <ul class="service-card__list">
        <li>
          <div class="service-card__authorized-date">
            {{ $t('passkey_created_on', { passkeyCreationTime: item.createdTime }) }}
          </div>
        </li>
        <li>
          <div
            v-if="item.lastAuthenticationTime"
            class="service-card__authorized-date"
          >
            {{ $t('passkey_last_used', { lastAuthenticationTime: item.lastAuthenticationTime }) }}
          </div>
          <div
            v-if="!item.lastAuthenticationTime"
            class="service-card__authorized-date"
          >
            {{ $t('passkey_never_used') }}
          </div>
        </li>
      </ul>
      <div class="email-controls">
        <div
          class="link email-controls--remove"
          @click.prevent="remove(item.credentialId, $event)"
        >
          {{ $t('label_remove') }}
        </div>
      </div>
    </div>
    <div
      v-if="items.length > 0"
      class="button button--warning button--small"
      style="margin-top: 24px"
      @click.prevent="removeAll($event)"
    >
      {{ $t('label_remove_all_passkeys') }}
    </div>
  </div>
</template>
