<script setup>
import { inject, reactive } from 'vue';
import Password from '@/main/resources/script/components/modal/password-modal-component.vue';
import RevokeTokens from '@/main/resources/script/components/modal/revoke-tokens-modal-component.vue';
import Email from '@/main/resources/script/components/modal/email-modal-component.vue';
import VerifyEmailOtp from '@/main/resources/script/components/modal/verify-email-otp-modal-component.vue';
import Phone from '@/main/resources/script/components/modal/phone-modal-component.vue';
import VerifyPhoneOtp from '@/main/resources/script/components/modal/verify-phone-otp-modal-component.vue';
import Passkey from '@/main/resources/script/components/modal/passkey-modal-component.vue';
import RevokeTokensManual from '@/main/resources/script/components/modal/revoke-tokens-manual-modal-component.vue';
import DeleteAccountModal from '@/main/resources/script/components/modal/delete-account-modal-component.vue';

defineProps({
  type: {
    type: String,
    required: true,
  },
});

const config = inject('config');
const modalOverlayState = reactive(config.modalOverlay);

function hideModalOverlay() {
  modalOverlayState.visible = false;
  modalOverlayState.type = '';
  modalOverlayState.data = {};
}
</script>

<template>
  <div class="modal-wrapper">
    <!-- Coming from main css -->
    <div
      class="modal-overlay-background"
      @click.prevent="hideModalOverlay"
    />
    <div class="container modal">
      <div
        class="modal-default-button"
        @click.prevent="hideModalOverlay"
      >
        &nbsp;✕&nbsp;
      </div>
      <Password v-if="type === config.modalOverlay.name.password" />
      <RevokeTokens v-if="type === config.modalOverlay.name.revokeTokens" />
      <Email v-if="type === config.modalOverlay.name.email" />
      <VerifyEmailOtp v-if="type === config.modalOverlay.name.verifyEmailOtp" />
      <Phone v-if="type === config.modalOverlay.name.phone" />
      <VerifyPhoneOtp v-if="type === config.modalOverlay.name.verifyPhoneOtp" />
      <Passkey v-if="type === config.modalOverlay.name.passkey" />
      <RevokeTokensManual v-if="type === config.modalOverlay.name.revokeTokensManual" />
      <DeleteAccountModal v-if="type === config.modalOverlay.name.deleteAccount" />
    </div>
  </div>
</template>

<style>
.modal {
    position: absolute;
    left: 50%;
    top: 50%;
    background: #fff;
    border-radius: 2px;
    padding: 20px;
    border: 1px solid #ededed;
    transform: translate(-50%, -50%);
    z-index: 999;
}

.modal-default-button {
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    font-size: 20px;
    z-index: 2;
}
</style>
