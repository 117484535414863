<script setup>
import { inject, reactive } from 'vue';
import '@/main/resources/assets/css/telenorid.css';
import Container from '@/main/resources/script/components/static/container-static-component.vue';
import Information from '@/main/resources/script/components/notifications/information-component.vue';
import HeaderPage from '@/main/resources/script/components/static/header-page-static-component.vue';
import Footer from '@/main/resources/script/components/static/footer-static-component.vue';
import Modal from '@/main/resources/script/components/modal-component.vue';

const config = inject('config');
const modalOverlayState = reactive(config.modalOverlay);
const notification = reactive({
  type: '',
  message: '',
});

function displayModalOverlay(type) {
  modalOverlayState.visible = true;
  modalOverlayState.type = type;
}

function toggle() {
  document.getElementById('what-can-I-expect-collapsible-button')
    .classList
    .toggle('collapsible__button--expanded');
  document.getElementById('what-can-I-expect-collapsible-content')
    .classList
    .toggle('collapsible__content--expanded');
}
</script>

<template>
  <HeaderPage
    :bread-crumb-link="config.navigation.privacy"
    :bread-crumb-text="$t('label_privacy')"
    page-class="privacy"
    :page-name="$t('privacy_delete_account')"
  />
  <Modal
    v-if="modalOverlayState.visible"
    :type="modalOverlayState.type"
  />
  <Container>
    <div
      v-show="notification.message"
      class="notification"
      :class="notification.type"
      v-html="notification.message"
    />
    <div class="privacy-request">
      <Information>
        {{ $t('privacy_request_account_deletion_intro') }}
      </Information>
      <div
        id="what-can-I-expect-collapsible"
        class="collapsible privacy-request-details"
      >
        <button
          id="what-can-I-expect-collapsible-button"
          class="collapsible__button"
          @click.prevent="toggle"
        >
          {{ $t('privacy_request_account_deletion_expectation_title') }}
        </button>
        <div
          id="what-can-I-expect-collapsible-content"
          class="collapsible__content"
        >
          <ul>
            <li class="privacy-request-details__bullet-point">
              {{ $t('privacy_request_account_deletion_expectation_intro') }}
            </li>
            <li class="privacy-request-details__bullet-point">
              {{ $t('privacy_request_account_deletion_expectation_warning') }}
            </li>
          </ul>
          <div v-html="$t('privacy_request_account_deletion_expectation_restoration')" />
        </div>
      </div>
      <br>
      <button
        class="show-modal-overlay button button--warning button--small"
        @click.prevent="displayModalOverlay(config.modalOverlay.name.deleteAccount)"
      >
        {{ $t('privacy_request_account_deletion_button') }}
      </button>
    </div>
  </Container>
  <Footer />
</template>
